import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { Carousel } from 'react-responsive-carousel';
import InnerImageZoom from 'react-inner-image-zoom';
import DemoImage from 'assets/images/test.jpg';
import DemoImage1 from 'assets/images/test1.jpg';
import './styles.css';
import { Footer } from 'components';

const ProductDetail = ({}) => {
  const [ data, setData ] = useState(
    {
      id: 0,
      title: 'Heather Lapelis Crop Blazer',
      description: 'esse nulla quem veniam irure amet ipsum sint export dolor quae elit quid irure nulla fugiat quorum dolor dolor irure quis legam quae dolor quae aute sunt veniam cillum dolore',
      imageList: [DemoImage, DemoImage1],
      color: ['#D1C9BC', '#000000'],
      price: '10000'
    },
  )
  const [ selectedColor, setSelectedColor ] = useState('#D1C9BC')

  const renderThumbs = (data) => {
    return data
  }

  const handleColorClick = (data) => {
    setSelectedColor(data)
  }

  return (
    <section>
      <div className='container mt-5'>
        <div className='row mt-5'>
          <div className='col-xs-12 col-sm-6' style={{minHeight: '100vh'}}>
            <Carousel
              showArrows={false}
              renderIndicator={false}
              autoPlay={false}
              showStatus={false}
              renderThumbs={renderThumbs}>
              {
                data.imageList.map((item, i) => (
                  <div key={i}>
                    <InnerImageZoom src={item} zoomSrc={item}>

                    </InnerImageZoom>
                  </div>
                ))
              }
            </Carousel>
          </div>
          <div className='d-flex col-xs-12 col-sm-6 flex-column justify-content-center' style={{minHeight: '100vh'}}>
            <h2>
              {data.title}
            </h2>
            <p>
              {data.description}
            </p>
            <h1>
              {data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")} ₮
            </h1>
            <div className='d-flex'>
              {
                data.color.map((item, i) => (
                  <div onClick={() => handleColorClick(item)} key={i} className={item == selectedColor ? 'selected-color-option color-options-container' : ' color-options-container'}>
                    <div className='color-option' style={{backgroundColor: item}}>

                    </div>
                  </div>
                ))
              }
            </div>
            <div className="form-group mt-4">
              <label htmlFor="exampleFormControlSelect1">Size select</label>
              <select className="form-control" id="sizeSelect">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div className='button-container my-5'>
              <button className='btn'>
                Add to cart
              </button>
            </div>
          </div>
        </div>
        <div style={{ borderTop: '1px solid #00000020'}}>
          <h1>Description</h1>
          <div>
            <p>“BKR® is a slick, bubbly glass water bottle with a soft, colorful silicone sleeve. BPA-free. all clean everything.®”</p>
            <p>We love our BKR® Bottles here at Yield, their glass container makes for a clean, environmentally friendly drinking vessel while the silicone sleeve does a great job protecting and insulating.</p>
            <p>• 500mL (16 oz)<br/>
            • glass bottle + silicone sleeve<br/>
            • polypropylene cap + silicone gasket<br/>
            • FDA approved, BPA-free, phthalate free<br/>
            • 100% reusable + recyclable</p>
          </div>
        </div>
        <div style={{ borderTop: '1px solid #00000020'}}>
          <h1>
            Related products
          </h1>
        </div>
      </div>
      <Footer>

      </Footer>
    </section>
  );
}

export default ProductDetail;
