import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';

import { Header } from 'components';
import Home from 'views/Home';
import ProductList from 'views/ProductList';
import ProductDetail from 'views/ProductDetail';

const App = ({}) => {
  return (
    <>
      <BrowserRouter>
        <Header scroll={true}/>
        <Switch>
          <Route
            exact
            path="/"
            name="Home"
            render={props => <Home {...props}/>}>

          </Route>
          <Route
            exact
            path="/products"
            name='Products list'
            render={props => <ProductList {...props}/>}>

          </Route>
          <Route
            exact
            path="/products/:id"
            name='Product detail'
            render={props => <ProductDetail {...props}/>}>

          </Route>
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Home {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Home {...props} />}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
