import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ProductListItem } from './components';
import InfiniteScroll from 'react-infinite-scroll-component';
import DemoImage from 'assets/images/test1.jpg';
import DemoImage1 from 'assets/images/test.jpg';

const demoData = [
  {
    id: 0,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC', '#000000'],
    price: '10000'
  },
  {
    id: 1,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC'],
    price: '10000'
  },
  {
    id: 2,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC', '#000000'],
    price: '10000'
  },
  {
    id: 3,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC'],
    price: '10000'
  },
  {
    id: 0,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC', '#000000'],
    price: '10000'
  },
  {
    id: 1,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC'],
    price: '10000'
  },
  {
    id: 2,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC', '#000000'],
    price: '10000'
  },
  {
    id: 3,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC'],
    price: '10000'
  },
  {
    id: 3,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC'],
    price: '10000'
  },
  {
    id: 0,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC', '#000000'],
    price: '10000'
  },
  {
    id: 1,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC'],
    price: '10000'
  },
  {
    id: 2,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC', '#000000'],
    price: '10000'
  },
  {
    id: 3,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC'],
    price: '10000'
  },
  {
    id: 3,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC'],
    price: '10000'
  },
  {
    id: 0,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC', '#000000'],
    price: '10000'
  },
  {
    id: 1,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC'],
    price: '10000'
  },
  {
    id: 2,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC', '#000000'],
    price: '10000'
  },
  {
    id: 3,
    title: 'Heather Lapelis Crop Blazer',
    image: DemoImage,
    hoverImage: DemoImage1,
    color: ['#D1C9BC'],
    price: '10000'
  },
]

const ProductList = ({location}) => {
  const [ data, setData ] = useState(demoData)
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  const fetchData = () => {
    console.log('test');
  }

  return (
    <div className='container-fluid mt-5 d-flex justify-content-center align-items-center' style={{minHeight: '100vh'}}>
      {
        loading ?
        <div className='spinner-border'>

        </div>
        :
        <InfiniteScroll
          dataLength={data.length} //This is important field to render the next data
          next={fetchData}
          hasMore={true}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          // below props only if you need pull down functionality
          // refreshFunction={refresh}
          // pullDownToRefresh
          // pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
          // }
          // releaseToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          // }
          >
            <div className='row'>
              {
                data.map((item, i) => (
                  <div className='col-xs-12 col-md-3' key={i}>
                    <ProductListItem data={item}>

                    </ProductListItem>
                  </div>
                ))
              }
            </div>
          </InfiniteScroll>
      }
    </div>
  );
}

export default ProductList;
