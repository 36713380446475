import React, { useEffect, useState, useRef } from 'react';
import './styles.css'
import ReactPageScroller from "react-page-scroller";

export default function Home() {
  return (
    <div>
      <ReactPageScroller animationTimer={600}>
        <div id='paragraph-1' className={'section'} style={{background: '#42273B'}}>
          <div className={'imageContainer'} style={{background: `url(https://images.pexels.com/photos/1083981/pexels-photo-1083981.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)`}}>
            <div className={'imageFilter'}>

            </div>
          </div>
          <div className={'contentContainer'}>
            <h1 className='mb-5 text-center'>
              U CAN WEAR WHATEVER U WANT.
            </h1>
            <button className={'shopNowButton'}>
              Shop now
            </button>
          </div>
        </div>
        <div id='paragraph-2' className={'section row'} style={{background: '#70566D'}}>
          <div className={'halfContainer col-6'}>
            <div className={'imageContainer'} style={{background: `url(https://images.pexels.com/photos/1083981/pexels-photo-1083981.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)`}}>
              <div className={'imageFilter'}>

              </div>
            </div>
            <div className={'contentContainer'}>
              <h1 className='mb-5 text-center'>
                Bags & Accessories.
              </h1>
              <button className={'shopNowButton'}>
                Shop now
              </button>
            </div>
          </div>
          <div className={'halfContainer col-6'}>
            <div className={'imageContainer'} style={{background: `url(https://images.pexels.com/photos/1083981/pexels-photo-1083981.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)`}}>
              <div className={'imageFilter'}>

              </div>
            </div>
            <div className={'contentContainer'}>
              <h1 className='mb-5 text-center'>
                Men Style
              </h1>
              <button className={'shopNowButton'}>
                Shop now
              </button>
            </div>
          </div>
        </div>
        <div id='paragraph-3' className={'section'} style={{background: '#ABC8C0'}}>
          <div className={'imageContainer'} style={{background: `url(https://images.pexels.com/photos/1083981/pexels-photo-1083981.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)`}}>
            <div className={'imageFilter'}>

            </div>
          </div>
          <div className={'contentContainer'}>
            <h1 className='mb-5 text-center'>
              Summer look.
            </h1>
            <button className={'shopNowButton'}>
              Shop now
            </button>
          </div>
        </div>
        <div id='paragraph-4' className={'section'} style={{background: '#DAF7DC'}}>
          <div className={'imageContainer'} style={{background: `url(https://images.pexels.com/photos/1083981/pexels-photo-1083981.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)`}}>
            <div className={'imageFilter'}>

            </div>
          </div>
          <div className={'contentContainer'}>
            <h1 className='mb-5 text-center'>
              Winter look.
            </h1>
            <button className={'shopNowButton'}>
              Shop now
            </button>
          </div>
        </div>
        <div id='paragraph-4' className={'section'} style={{background: '#DAF7DC'}}>
          <div className={'imageContainer'} style={{background: `url(https://images.pexels.com/photos/1083981/pexels-photo-1083981.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)`}}>
            <div className={'imageFilter'}>

            </div>
          </div>
          <div className={'contentContainer'}>
            <h1 className='mb-5 text-center'>
              New in shop.
            </h1>
            <button className={'shopNowButton'}>
              Shop now
            </button>
          </div>
        </div>
      </ReactPageScroller>
    </div>
  )
}
