import React, {useCallback, useEffect, useState} from 'react'
import './styles.css';
import Logo from '../../assets/images/logo.png';
import Cart from '../../assets/images/cart.svg';
import { Link } from 'react-router-dom';
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";

const Header = ({scroll}) => {
  const [open, setOpen] = useState(false)
  const [ scrolled, setScrolled ] = useState(false)

  useEffect(() => {
    window.onscroll = () => {
      if(window.pageYOffset > 10) {
        setScrolled(true)
      }
      else {
        setScrolled(false)
      }
    }
  }, [])

  const handleScroll = () => {
    // if(window.scrollY)
  }

  return (
    <header className={(scrolled && scroll) ? 'header scrolled' : 'header'}>
      <div className='d-flex d-md-none' style={{
          fontSize: '30px'
        }} onClick={() => setOpen(true)}>
        &#9776;
      </div>
      <div>
        <Link to='/'>
          <img src={Logo} alt='Logo' height={45}></img>
        </Link>
      </div>
      <div className='d-none d-lg-flex flex-fill align-items-center ml-5'>
        <div className='hoverButton'>
          <div style={{ cursor: 'pointer' }} className='nav'>
            Shop
          </div>
          <div className='expand-container'>
            <Link to='/products' className='nav'>
              BEST SELLER
            </Link>
            <Link to='/products' className='nav'>
              BACK IN STOCK
            </Link>
            <Link to='/products' className='nav'>
              OUTLET
            </Link>
          </div>
        </div>
        <Link to='/products?params=test' className='nav'>
          Top
        </Link>
        <Link to='/products' className='nav'>
          Bottom
        </Link>
        <Link to='/products' className='nav'>
          Blouse & Shirts
        </Link>
        <Link to='/products' className='nav'>
          Hoodie & Sweats
        </Link>
        <Link to='/products' className='nav'>
          Outwear
        </Link>
        <Link to='/products' className='nav'>
          Dress
        </Link>
        <Link to='/products' className='nav'>
          Set
        </Link>
        <Link to='/products' className='nav'>
          Accessories
        </Link>
      </div>
      <div className='d-flex align-items-center'>
        <img src={Cart} alt='cart' height={30}></img>
      </div>
      <div className={'mobileNav'} style={open ? { left: 0 } : { left: '-100vw' }}>
        <div className={'closeButton'} onClick={() => setOpen(false)}>
          &times;
        </div>
        <div className={'mobileNavContentContainer'}>
          <div className='mobileHoverButton'>
            <div style={{ cursor: 'pointer' }} className='nav' onClick={(e) => {
              e.target.closest('.mobileNavContentContainer').classList.toggle('open')
            }}>
              Shop
            </div>
            <div className='expand-container ml-3'>
              <Link to='/products' className='nav' onClick={() => {setOpen(false)}}>
                BEST SELLER
              </Link>
              <Link to='/products' className='nav' onClick={() => {setOpen(false)}}>
                BACK IN STOCK
              </Link>
              <Link to='/products' className='nav' onClick={() => {setOpen(false)}}>
                OUTLET
              </Link>
            </div>
          </div>
          <Link to='/products?params=test' className='nav' onClick={() => {setOpen(false)}}>
            Top
          </Link>
          <Link to='/products' className='nav' onClick={() => {setOpen(false)}}>
            Bottom
          </Link>
          <Link to='/products' className='nav' onClick={() => {setOpen(false)}}>
            Blouse & Shirts
          </Link>
          <Link to='/products' className='nav' onClick={() => {setOpen(false)}}>
            Hoodie & Sweats
          </Link>
          <Link to='/products' className='nav' onClick={() => {setOpen(false)}}>
            Outwear
          </Link>
          <Link to='/products' className='nav' onClick={() => {setOpen(false)}}>
            Dress
          </Link>
          <Link to='/products' className='nav' onClick={() => {setOpen(false)}}>
            Set
          </Link>
          <Link to='/products' className='nav' onClick={() => {setOpen(false)}}>
            Accessories
          </Link>
          <div className='d-flex px-2'>
            <a href="https://www.facebook.com/rglabel/" target='__blank' className='p-2 mr-1'>
              <AiFillFacebook style={{color: '#222222'}}></AiFillFacebook>
            </a>
            <a href='https://www.instagram.com/rglabelclothing/' target='__blank' className='p-2'>
              <AiFillInstagram style={{color: '#222222'}}></AiFillInstagram>
            </a>
          </div>
        </div>
      </div>
    </header>
);
}

export default Header;
