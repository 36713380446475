import React from 'react';
import './styles.css';
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";

const Footer = ({}) => {
  return (
    <footer className='container-fluid footer mt-5'>
      <div className='container'>
        <div className='d-flex justify-content-center'>
          <a href="https://www.facebook.com/rglabel/" target='__blank' className='p-2 mr-1'>
            <AiFillFacebook style={{color: '#222222'}}></AiFillFacebook>
          </a>
          <a href='https://www.instagram.com/rglabelclothing/' target='__blank' className='p-2'>
            <AiFillInstagram style={{color: '#222222'}}></AiFillInstagram>
          </a>
        </div>
        <div className='text-center'>
          Copyright © 2020 RGLabel
        </div>
      </div>
    </footer>
  );
}

export default Footer;
