import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';

const ProductListItem = ({data}) => {
  return (
    <Link className='d-flex product-list-item-container my-4' to={`/products/${data.id}`}>
      <div className='image-container'>
        <img src={data.image}>

        </img>
        <img src={data.hoverImage} className='hoverImage'>

        </img>
      </div>
      <div className='description'>
        <div className='main-info'>
          <h6>
            {
              data.title
            }
          </h6>
          <div className='colors-container'>
            {
              data.color.map((item, i) => (
                <div key={`color-${i}`} className='colors-option' style={{backgroundColor: item}}>

                </div>
              ))
            }
          </div>
        </div>
        <div className='price'>
          <h6 className='text-right'>
            {data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")} ₮
          </h6>
        </div>
      </div>
    </Link>
  );
}

export default ProductListItem;
